import './Carousel.css'
import { useState } from "react"

export const Carousel = () => {
  const [selected, setSelected] = useState(0);

  const carouselList = [
    {
      video: 'https://www.youtube.com/embed/6oM-J7tqh8o?feature=oembed',
      title: 'Full Reel',
      thumbnailSrc: 'Full Reel IMG',
    },
    {
      video: 'https://www.youtube.com/embed/JW0sjOw9PMQ?feature=oembed',
      title: 'Ballet/Tap',
      thumbnailSrc: 'Ballet/Tap IMG',
    },
    {
      video: 'https://www.youtube.com/embed/MTCx_SGkcNg?feature=oembed',
      title: 'Ballroom',
      thumbnailSrc: 'Ballroom IMG',
    },
    {
      video: 'https://www.youtube.com/embed/Z1kDlbaitOY?feature=oembed',
      title: 'Swing',
      thumbnailSrc: 'Swing IMG',
    },
    {
      video: 'https://www.youtube.com/embed/L994trJE9MA?feature=oembed',
      title: 'Partnering',
      thumbnailSrc: 'Partnering IMG',
    }
  ]

  const handleClickThumbnail = (e, clickIndex) => {
    e.preventDefault();
    setSelected(clickIndex);
  }
  return (<div className='carousel'>
    <div className='carousel-body'>
      <iframe
        className="carousel-player"
        title={carouselList[selected].title}
        src={carouselList[selected].video}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen=""
        name="fitvid0"></iframe>
    </div>
    <ul className='carousel-list'>
      {carouselList.map((item, index) =>
        <li
          key={`key-${index}`}
          onClick={(e) => handleClickThumbnail(e, index)}
          class="carousel-item">
          {item.title}
        </li>
      )}
    </ul>
  </div>)
}