import './App.css';
import './Reel.css';
import { Section } from "./components/Section";
import { Carousel } from "./components/Carousel";

export const Reel = () => {
  return (<Section id="reel" className="iceBlue">
    <div class="onehundred centerCenter">
      <Carousel />
    </div>
  </Section>);
};