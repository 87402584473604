import './App.css';
import './Documents.css';
import { DownloadButton } from "./components/Button";
import { Section } from "./components/Section";

export const Documents = () => {
  return (<Section id="resume" className="midGreen resumeSection">
    <div class="fifty centerCenter">
      <a target="_blank" rel="nofollow noopener" href="/assets/Resume_Cooknick-Hailey.pdf"><img alt="resume" className="resume dropShadow" src='/assets/Resume_Cooknick-Hailey.png' /></a>
      <DownloadButton download href="/assets/Resume_Cooknick-Hailey.pdf">Download Resume</DownloadButton>
    </div>
    <div class="fifty centerCenter">
      <a target="_blank" rel="nofollow noopener" href="/assets/Headshot_Cooknick-Hailey.pdf" ><img alt="headshot" className="resume dropShadow" src='/assets/Headshot_Cooknick-Hailey.png' /></a>
      <DownloadButton download href="/assets/Headshot_Cooknick-Hailey.pdf">Download Headshot</DownloadButton>
    </div>
  </Section>);
}