import './App.css';
import { ChoreoReel } from './Choreo';
import { Contact } from './Contact';
import { Documents } from './Documents';
import { Reel } from './Reel';
import { Splash } from './Splash';
import { Header } from './components/Header';

function App() {
  return (
    <>
      <Header />
      <main>
        <div class="mainContent">

          <Splash />

          <Documents />

          <Reel />
          
          <ChoreoReel />

          <Contact />

        </div >
      </main >
    </>
  );
}

export default App;
