import { useEffect, useState } from 'react';
import './Header.css';
export const Header = () => {

  const [isOpen, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 350) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (<header>
    <div className={`navbarWrapper ${isVisible ? 'active' : 'inactive'}`} >
      <div class="navbarContent">
        <nav>
          <div class="staticContent">
            <span className='navbarTitle'>Hailey Cooknick</span>

            <div class="hideMobile desktop-navList">
              <ul>
                <li><a href="#resume">Resume</a></li>
                <li><a href="#reel">Reel</a></li>
                <li><a href="#choreo">Choreography Reel</a></li>
                <li><a href="#contact">Contact</a></li>
              </ul>
            </div>

            <div class="hideDesktop">
              <button
                onClick={() => setOpen(prev => !prev)}
                aria-expanded={isOpen}
                className="icon-button"
              >
                {isOpen
                  ? <img alt="close icon" src='/assets/x-mark-lined.png' className='icon' />
                  : <img alt="menu icon" src='/assets/menu-lined.png' className='icon' />}
              </button>
            </div>
          </div>
          <div className={`expandibleContent ${isOpen ? 'active' : null}`}>
            <ul>
              <li><a href="#resume">Resume</a></li>
              <li><a href="#reel">Reel</a></li>
              <li><a href="#choreo">Choreography Reel</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>)
}