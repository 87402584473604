import './App.css';
import './Splash.css';
import './components/Button.css'
import { Section } from './components/Section';

export const Splash = () => {
  return (<Section className="darkGreen">
    <div class="fifty centerCenter splash">
      <h1 className='thin'>Hailey Cooknick</h1>
      <p>It's a pleasure to see you.</p>
      <div className='splashLinks'>
        <a className='button link-button' href="#resume">Resume</a>
        <a className='button link-button' href="#reel">Reel</a>
        <a className='button link-button' href="#choreo">Choreography Reel</a>
        <a className='button link-button' href="https://drive.google.com/drive/folders/1KoJVWnfU6CIC2CXFYXB2M2GC42yPzRFE?usp=sharing">Vocal Clips</a>
      </div>
    </div>
    <div class="fifty centerCenter">
      <img alt="Hailey's headshot" className="headshot dropShadow" src='/assets/Hailey-Cooknick.jpeg' />
    </div>
  </Section>);
};