import './App.css';
import './Contacts.css';
import { Section } from "./components/Section";

export const Contact = () => {
  return (<Section id="contact" className="whiteBg contact-wrapper">
    <div class="fifty centerCenter">
      <img alt="Hailey's social media profile thumbnail" className='contact-pic' src="./assets/insta/profile-pic.jpg" />
      <div class="contact-list">
        <p>Hailey Cooknick</p>
        <p><a href="mailto:hailey.cooknick@gmail.com">hailey.cooknick@gmail.com</a></p>
        <p><a href="https://www.instagram.com/hailey.cooknick/">@hailey.cooknick</a></p>
      </div>
    </div>
    <div class="fifty centerCenter">
      <div class="insta-row">
        <img alt="Hailey and her cast" className='insta-img' src="./assets/insta/rws.jpg" />
        <img alt="Hailey at 1920s themed dance event" className='insta-img' src="./assets/insta/leg.jpg" />
        <img alt="Hailey and her husband hugging" className='insta-img' src="./assets/insta/hug.jpg" />
      </div>
    </div>
  </Section>);
};
