import './App.css';
import './Choreo.css';
import { Section } from "./components/Section";

export const ChoreoReel = () => {
  return (<Section id="choreo" className="choreoGreen">
    <div class="onehundred centerCenter">
      <div className='carousel'>
        <div className='carousel-body'>
          <iframe
            className="player"
            title="Choreography Reel"
            src='https://www.youtube.com/embed/uN6WQOrZf7w?feature=oembed'
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""
            name="fitvid0"></iframe>
        </div>
      </div>
    </div>
  </Section>);
};