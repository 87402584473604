import './Button.css'

export const Button = ({ children, ...rest }) => {
  return (<button className="button" {...rest}>
    {children}
  </button>)
}

export const LinkButton = ({ children, ...rest }) => {
  return (<a className="button link-button" target="_blank" rel="nofollow noopener" {...rest}>
    {children}
  </a>)
}

export const DownloadButton = ({ children, ...rest }) => {
  return (<a className="button download-button" target="_blank" rel="nofollow noopener" {...rest}>
    {children}
  </a>)
}